<template>
    <div class="px-4 sm:px-6 lg:px-8 pt-6">
        <div class="w-full block">
            <BaseStrapi404Page v-if="pageStore.pageDoesNotExist" />
            <div v-else>
                <StrapiComponent
                    v-for="(section, index) in pageHeader"
                    :key="index"
                    :component="section" />
                <PageElementWrapper v-if="hasPageMenu">
                    <div class="lg:grid grid-cols-1 gap-8 lg:grid-cols-5">
                        <div class="col-span-1">
                            <BaseStrapiPageMenu />
                        </div>
                        <div class="col-span-4">
                            <div class="-mx-1 lg:-mx-8">
                                <StrapiComponent
                                    v-for="(section, index) in pageContent"
                                    :key="index"
                                    :component="section" />
                            </div>
                        </div>
                    </div>
                </PageElementWrapper>
                <StrapiComponent
                    v-for="(section, index) in pageContent"
                    v-else
                    :key="index"
                    :component="section" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {usePageStore} from "@/store-pinia/page";

const pageStore = usePageStore()
const pageData = computed(() => pageStore.pageData)
const pageDoesNotExist = computed(() => pageStore.pageDoesNotExist)

const pageHeader = computed(() => pageData.value?.attributes?.Kopfzeile ?? [])
const pageContent = computed(() => pageData.value?.attributes?.Inhalt ?? [])
const hasPageMenu = computed(() => pageData.value?.attributes?.SeitenMenu ?? false)
</script>